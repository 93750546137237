import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedin,
  faMediumM,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faGlobeEurope } from "@fortawesome/free-solid-svg-icons";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import styled from "styled-components";
import Title from "./elements/title";
import Text from "./elements/text";
import Emoji from "./elements/emoji";
import me from "../images/me.png";

config.autoAddCss = false;

const SOCIAL = [
  {
    icon: faGithub,
    name: "Github",
    url: "https://github.com/sarkaaa",
  },
  {
    icon: faLinkedin,
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/sarkachwastkova/",
  },
  {
    icon: faInstagram,
    name: "Instagram",
    url: "https://www.instagram.com/sarka.codes/",
  },
  {
    icon: faMediumM,
    name: "Medium",
    url: "https://medium.com/@sarkachwastkova",
  },
  {
    icon: faEnvelope,
    name: "Email",
    url: "mailto:chwastkova.s@gmail.com",
  },
];

const PrefooterWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 2rem 0;
  margin: 1rem 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;

  @media screen and (min-width: 450px) {
    flex-direction: row;
  }
`;

const Avatar = styled.img`
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 3.75rem;
  margin-right: 2rem;

  @media screen and (min-width: 450px) {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 2.25rem;
  }
`;

const IconsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1rem 0;
`;

export const Icon = styled.a`
  padding: 0.3rem;
  margin: 0 0.4rem;
  border: 2px dashed #fff;
  transition: ease-in-out 0.2s;

  & > * {
    color: #0c9cad;
    font-size: 2rem;
  }

  &:hover {
    border-bottom: 2px dashed #c64561;
  }

  &:focus,
  &:active {
    border: 2px dashed #c64561;
  }
`;

const Prefooter = () => (
  <PrefooterWrapper>
    <Title size="h2">
      O mně <Emoji label="itgirl" symbol="👩🏻‍💻" />
    </Title>
    <Container>
      <Avatar src={me} />
      <Text>
        Jmenuji se Šárka a baví mě vše okolo web developmentu. Mám zkušenosti s
        frontendem, QA, znám trochu i backend a rozumím základním principům UX.
        Aktuálně bydlím v Brně a kromě IT ráda chodím na výšlapy, jezdím na
        kole, na výlety a mám ráda kočky. <Emoji label="cat" symbol="🐈" />
      </Text>
    </Container>
    <IconsWrapper>
      {SOCIAL.map(({ icon, name, url }) => (
        <Icon key={url} href={url} alt={name} target="_blank">
          <FontAwesomeIcon icon={icon} />
        </Icon>
      ))}
    </IconsWrapper>
  </PrefooterWrapper>
);

export default Prefooter;
