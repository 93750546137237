import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMedium } from '@fortawesome/free-brands-svg-icons'
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Prefooter from "../components/prefooter"
import Title from "../components/elements/title"
import Text from "../components/elements/text"
import BlogFeed from "../components/blogFeed"

config.autoAddCss = false

const IndexPage = () => {
  return (
    <Layout showIG>
      <SEO title="Home" />
      <Title size="h2">Pandacode</Title>
      <Text>
        Pandacode je můj osobní web, kde sdílím odkazy na své články na Medium, táháky nebo projekty.
      </Text>
      <Title size="h2">Blogposty na <FontAwesomeIcon icon={faMedium} /></Title>
      <BlogFeed readMore />
      <Prefooter />
    </Layout>
  )
}

export default IndexPage
